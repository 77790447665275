import { nanoid } from "nanoid";

export const dateTypeEnum = [
    { name: "业绩核算月份", value: 1, type: "month", format: "yyyy-MM" },
]

// 函数创建一个空的菜单数据
export function initialPB() {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}
