import forList from "@/mixin/forList";
import { dateTypeEnum } from "./config";
export default {
    name: "CommissionList",
    noFetchFirst: true,
    mixins: [forList],
    data() {
        return {
            searchTypeEnum: [
                { name: "客户编号", value: 1 },
                { name: "认购人姓名", value: 2 },
                { name: "订单编号", value: 3 },
                { name: "销售员工", value: 4 },
                { name: "销售主管", value: 5 },
                { name: "销售经理", value: 6 }
            ],     // 模糊查询字段选项
            dateTypeEnum,         // 供查询的日期类型选项
            SP_: {
                searchType: 1,
                searchValue: "",
                searchTimeType: 1,
                searchStartTime: "",
                searchEndTime: "",
            },
            tableData: [],
        };
    },

    computed: {
        timeType() {
            const { searchTimeType } = this.SP_;
            return dateTypeEnum.find(({ value }) => value === searchTimeType) || dateTypeEnum[0];
        }
    },
    created() {
        this.auth.exportOrder = this.$hasAuthFor("api/performanceCal/exportOrderPerformanceCal");
        this.auth.delete = this.$hasAuthFor("api/performanceCal/deletePerformanceCal");

        const { params } = this.$route;
        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.handleSearch();
        }
        else this.fetchData();
    },

    methods: {
        clearSP_trim() {
            this.SP_.searchType = 1,
                this.SP_.searchTimeType = 1;
        },

        async fetchData() {
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/performanceCal/queryOrderPerformanceCalList",
                    method: "post",
                    data: {
                        ...this.extractParams(),
                    }
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.performanceCalList;
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        // 导出债券绩效
        async handleExportBtn(flag) {
            if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
                return this.$message({
                    message: "请选择时间范围",
                    type: "warning"
                });

            let loadingRef,
                url = "/api/performanceCal/exportOrderPerformanceCal";
            try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        //删除
        async handleBulkDelete(row) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/performanceCal/deletePerformanceCal",
                    method: "post",
                    data: {
                        id: row.id
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        }
    }
};